@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,900&display=swap");
@import url("https://cdnjs.cloudflare.com/ajax/libs/material-design-iconic-font/2.2.0/css/material-design-iconic-font.min.css");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap");

:root {
  --primary-color: #3e3934; /* primary */
  --secondary-color: #cb2330; /* secondary */
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  font-size: 14px;
  color: #4c5258;
  letter-spacing: 0.5px;
  font-family: Roboto, sans-serif;
  background-color: #f7f8fa;
  overflow-x: hidden;
}

.img-card-multi {
  max-width: 300px;
  white-space: break-spaces;
}

.job-card-multi {
  max-width: 425px;
  white-space: break-spaces;
}

.job-card-multi .card-logo-img,
.job-card-multi .pirds,
.img-card-multi .card-logo-img,
.img-card-multi .pirds {
  margin: 5px;
  vertical-align: top;
}
.img-card-multi .pirds {
  height: 76px;
}

fieldset {
  display: block;
  width: 100%;
  padding-block-start: 0.35em;
  padding-inline-start: 0.75em;
  padding-inline-end: 0.75em;
  padding-block-end: 0.625em;
  min-inline-size: min-content;
  border-width: 2px !important;
  border-style: groove;
  border-color: threedface;
  border-image: initial;
  margin: 0;
}
legend {
  display: block;
  padding-inline-start: 2px;
  padding-inline-end: 2px;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
  float: none;
  width: auto;
  margin-bottom: 0;
}
legend h3 {
  font-size: 22px;
  font-weight: bold;
}

.error-msg {
  max-height: 300px;
  overflow: auto;
}
ul.pagination {
  justify-content: center;
}
.page-link {
  color: var(--secondary-color);
}
.page-link:hover {
  color: var(--primary-color);
}
.page-link:focus,
.active > .page-link,
.page-link.active {
  color: white;
  background-color: var(--secondary-color);
  border-color: var(--secondary-color);
}

.btn,
.form-control,
.form-select {
  border-radius: 0.25rem;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.certi-logn {
  max-width: 700px;
  display: block;

  white-space: pre-wrap;
}

.loader {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: none;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #ffffff9e;
  z-index: 1111111111111111;
}
.loader.in {
  display: flex;
}
.loader .main-spin {
  width: 70px;
  height: 70px;

  border-color: var(--secondary-color);
  border-right-color: transparent;
}

.wrapper {
  width: auto;
  position: relative;
}

a {
  color: var(--secondary-color);
  text-decoration: none;
}
a:hover {
  color: #000000;
  text-decoration: none;
}

::placeholder {
  opacity: 0.4 !important;
}

/* Header */

.top-header .navbar {
  position: fixed;
  top: 0;
  left: 260px;
  right: 0;
  background-color: #f7f8fa;
  border-bottom: 1px solid #e2e3e4;
  background-clip: padding-box;
  height: 60px;
  z-index: 10;
  padding: 0 1.5rem;
  transition: all 0.2s;
}

.mt-btn {
  margin-top: 29px;
}

.top-header .navbar .top-navbar-right .nav-link {
  color: #293445;
  font-size: 18px;
  font-weight: 500;
  padding-right: 0.8rem;
  padding-left: 0.8rem;
}

.top-header .navbar .mobile-toggle-icon,
.top-header .navbar .search-toggle-icon {
  color: #293445;
  font-size: 18px;
  font-weight: 500;
  display: none;
  cursor: pointer;
}

.top-header .navbar .messages,
.top-header .navbar .notifications {
  position: relative;
}

.top-header .navbar .searchbar {
  position: relative;
  width: 30%;
}

.top-header .navbar .searchbar .search-close-icon {
  right: 2rem;
  opacity: 0.5;
  cursor: pointer;
  display: none;
}

.top-header .navbar .searchbar .form-control {
  border-radius: 30px;
  box-shadow: none;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  border: 1px solid #e2e3e4;
}

.top-header .navbar .dropdown-searchbar .form-control {
  border-radius: 30px;
  border: 1px solid rgb(206 212 218 / 0%);
  padding-left: 3rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

.top-header .navbar .searchbar .form-control:focus,
.top-header .navbar .dropdown-searchbar .form-control:focus {
  border-color: #86b7fe;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.top-header .navbar .searchbar .search-icon,
.top-header .navbar .dropdown-searchbar .search-icon {
  opacity: 0.5;
}

.dropdown-toggle-nocaret:after {
  display: none;
}

.top-header .navbar .dropdown-menu {
  -webkit-animation: 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards 1
    animdropdown;
  animation: 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards 1
    animdropdown;
}

@-webkit-keyframes animdropdown {
  from {
    -webkit-transform: translate3d(0, 6px, 0);
    transform: translate3d(0, 6px, 0);
    opacity: 0;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes animdropdown {
  from {
    -webkit-transform: translate3d(0, 6px, 0);
    transform: translate3d(0, 6px, 0);
    opacity: 0;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.top-header .navbar .dropdown-large,
.dropdown-user-setting .navbar .dropdown-large {
  position: relative;
}

.dropdown-large .dropdown-menu {
  width: 360px;
  border: 0;
  padding: 0.5rem;
  border-radius: 10px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.top-header .navbar .dropdown-large .dropdown-menu::after {
  content: "";
  width: 13px;
  height: 13px;
  background: #fff;
  position: absolute;
  top: -6px;
  right: 16px;
  transform: rotate(45deg);
  border-top: 1px solid #ddd;
  border-left: 1px solid #ddd;
}

.top-header .navbar .dropdown-menu::after {
  content: "";
  width: 13px;
  height: 13px;
  background: #ffff;
  position: absolute;
  top: -6px;
  right: 16px;
  transform: rotate(45deg);
  border-top: 1px solid #ddd;
  border-left: 1px solid #ddd;
}

.dropdown-user-setting .dropdown-menu {
  width: 280px;
  border: 0;
  padding: 0.5rem;
  border-radius: 10px;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.top-header .navbar .dropdown-user-setting .dropdown-menu::after {
  content: "";
  width: 13px;
  height: 13px;
  background: #fff;
  position: absolute;
  top: -6px;
  right: 16px;
  transform: rotate(45deg);
  border-top: 1px solid #ddd;
  border-left: 1px solid #ddd;
}

.top-header .navbar .dropdown-menu::after {
  content: "";
  width: 13px;
  height: 13px;
  background: #ffff;
  position: absolute;
  top: -6px;
  right: 16px;
  transform: rotate(45deg);
  border-top: 1px solid #ddd;
  border-left: 1px solid #ddd;
}

.top-header .navbar .dropdown-item {
  padding: 0.5rem;
}

.top-header .navbar .dropdown-large .dropdown-item:hover,
.top-header .navbar .dropdown-user-setting .dropdown-item:hover {
  border-radius: 10px;
  color: #1e2125;
  background-color: #f5f5f5;
}

.top-header .navbar .dropdown-item .setting-icon {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 18px;
  background-color: #e1e6eb;
}

.top-header .navbar .dropdown-item .setting-text {
  font-size: 16px;
  font-weight: 500;
}

.top-header .navbar .user-img {
  width: 38px;
  height: 38px;
  padding: 0px;
  border-radius: 50%;
}

.top-header .navbar .user-name {
  font-size: 15px;
  color: #5e636b;
  font-weight: 500;
  padding-right: 10px;
}

.top-header .navbar .dropdown-menu .apps:hover {
  background-color: #f8f9fa;
  transition: all 0.2;
}

.top-header .navbar .dropdown-menu .apps .apps-icon-box {
  color: #34383f;
  width: 50px;
  height: 50px;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e9ecef;
  margin: auto;
  border-radius: 50%;
}

.top-header .navbar .dropdown-menu .apps .apps-name {
  color: #34383f;
  font-size: 15px;
}

.top-header .navbar .dropdown-menu .msg-time {
  font-size: 12px;
}

.top-header .navbar .dropdown-menu .dropdown-msg-user {
  font-size: 14px;
}

.top-header .navbar .dropdown-menu .dropdown-msg-text {
  font-size: 13px;
}

.header-message-list {
  position: relative;
  height: 400px;
}

.header-notifications-list {
  position: relative;
  height: 400px;
}

.top-header .navbar .dropdown-menu .notification-box {
  width: 48px;
  height: 48px;
  font-size: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e1e6eb;
}

.top-header .navbar .nav-link .notify-badge {
  position: absolute;
  top: -8px;
  right: -8px;
  color: #fff;
  font-size: 13px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f50d0d;
}

/* Sidebar */

.sidebar-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 260px;
  height: 100%;
  background-color: #f7f8fa;
  background-clip: padding-box;
  border-right: 1px solid #e2e3e4;
  transition: all 0.2s;
  z-index: 16;
}

.sidebar-wrapper .sidebar-header {
  width: 260px;
  height: 60px;
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  bottom: 0;
  padding: 0 15px;
  z-index: 5;
  background: #f7f8fa;
  border-right: 1px solid #e2e3e4;
  border-bottom: 1px solid #e4e4e4;
  background-clip: padding-box;
  transition: all 0.2s;
}

.sidebar-wrapper .sidebar-header .logo-icon {
  width: 65%;
}

.sidebar-wrapper .sidebar-header .logo-text {
  font-size: 22px;
  margin-left: 10px;
  margin-bottom: 0;
  letter-spacing: 1px;
  color: var(--secondary-color);
}

.toggle-icon {
  font-size: 26px;
  cursor: pointer;
  color: var(--secondary-color);
}

.sidebar-wrapper ul {
  padding: 0;
  margin: 0;
  list-style: none;
  background: 0 0;
}

.sidebar-wrapper .metismenu {
  background: 0 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 10px;
  margin-top: 60px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.sidebar-wrapper .metismenu li + li {
  margin-top: 5px;
}

.sidebar-wrapper .metismenu li:first-child {
  margin-top: 5px;
}

.sidebar-wrapper .metismenu li:last-child {
  margin-bottom: 5px;
}

.sidebar-wrapper .metismenu > li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
}

.sidebar-wrapper .metismenu a {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: left;
  padding: 10px 15px;
  border-radius: 4px;
  font-size: 15px;
  color: #5f5f5f;
  outline-width: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  letter-spacing: 0.5px;
  border-left: 4px solid rgb(255 255 255 / 0%);
  transition: all 0.2s ease-out;
}

.sidebar-wrapper .metismenu a .parent-icon {
  font-size: 18px;
  line-height: 1;
}

.sidebar-wrapper .metismenu a .menu-title {
  margin-left: 10px;
}

.sidebar-wrapper .metismenu ul a {
  padding: 6px 15px 6px 15px;
  font-size: 15px;
  border: 0;
  border-left: 4px solid rgb(255 255 255 / 0%);
}

.sidebar-wrapper .metismenu ul a i {
  margin-right: 10px;
  font-size: 10px;
}

.sidebar-wrapper .metismenu ul {
  border: 1px solid #ffffff00;
  background: rgb(255 255 255 / 0%);
}

.sidebar-wrapper .metismenu ul ul a {
  padding: 8px 15px 8px 30px;
}

.sidebar-wrapper .metismenu ul ul ul a {
  padding: 8px 15px 8px 45px;
}

.sidebar-wrapper .metismenu .mm-active > a,
.sidebar-wrapper .metismenu a:active,
.sidebar-wrapper .metismenu a:focus,
.sidebar-wrapper .metismenu a:hover {
  color: var(--secondary-color);
  text-decoration: none;
  background-color: rgb(255 255 255);
  border-left: 4px solid var(--secondary-color);
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%);
}

.menu-label {
  padding: 20px 15px 5px 5px;
  color: #b0afaf;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 0.5px;
}

.metismenu .has-arrow:after {
  position: absolute;
  content: "";
  width: 0.5em;
  height: 0.5em;
  border-style: solid;
  border-width: 1.2px 0 0 1.2px;
  border-color: initial;
  right: 15px;
  transform: rotate(-45deg) translateY(-50%);
  transform-origin: top;
  top: 50%;
  transition: all 0.3s ease-out;
}

@media screen and (min-width: 1025px) {
  .wrapper.toggled:not(.sidebar-hovered)
    .sidebar-wrapper
    .sidebar-header
    .logo-text {
    display: none;
  }
  .wrapper.toggled:not(.sidebar-hovered) .sidebar-wrapper {
    width: 70px;
  }
  .wrapper.toggled.sidebar-hovered .sidebar-wrapper {
    box-shadow: 0 0.3rem 0.8rem rgba(0, 0, 0, 0.12) !important;
  }
  .wrapper.toggled:not(.sidebar-hovered) .sidebar-wrapper .sidebar-header {
    width: 70px;
  }
  .wrapper.toggled:not(.sidebar-hovered)
    .sidebar-wrapper
    .sidebar-header
    .toggle-icon {
    display: none;
  }
  .wrapper.toggled:not(.sidebar-hovered) .sidebar-wrapper .sidebar-header {
    justify-content: center;
  }
  .wrapper.toggled:not(.sidebar-hovered) .sidebar-wrapper .sidebar-header {
    width: 70px;
  }
  .wrapper.toggled:not(.sidebar-hovered) .sidebar-wrapper .metismenu a {
    justify-content: center;
    border-left: 0px solid var(--secondary-color);
  }
  .wrapper.toggled:not(.sidebar-hovered)
    .sidebar-wrapper
    .metismenu
    .menu-title {
    display: none;
  }
  .wrapper.toggled:not(.sidebar-hovered) .sidebar-wrapper .metismenu li ul {
    display: none;
  }
  .wrapper.toggled:not(.sidebar-hovered)
    .sidebar-wrapper
    .metismenu
    li.menu-label {
    display: none;
  }
  .wrapper.toggled:not(.sidebar-hovered)
    .sidebar-wrapper
    .metismenu
    .has-arrow:after {
    display: none;
  }
  .email-toggle-btn {
    display: none !important;
  }
  .chat-toggle-btn {
    display: none !important;
  }
}

.wrapper.toggled .top-header .navbar {
  left: 70px;
}

.wrapper.toggled .page-content {
  margin-left: 70px;
}

/* Page Content */

.page-content {
  margin-left: 260px;
  margin-top: 60px;
  padding: 1.5rem;
  transition: all 0.2s;
}

/* Utilities */

.radius-10 {
  border-radius: 10px;
}
.radius-15 {
  border-radius: 15px;
}
.radius-30 {
  border-radius: 30px;
}

.font-13 {
  font-size: 13px;
}

.font-14 {
  font-size: 14px;
}

.font-20 {
  font-size: 20px;
}
.font-22 {
  font-size: 22px;
}
.font-24 {
  font-size: 24px;
}
.font-30 {
  font-size: 30px;
}

.font-35 {
  font-size: 35px;
}

.text-justify {
  text-align: justify;
}

.table-responsive {
  white-space: nowrap;
}

.fm-menu .list-group a {
  font-size: 16px;
  color: #5f5f5f;
  display: flex;
  align-items: center;
}

.fm-menu .list-group a i {
  font-size: 23px;
}

.fm-menu .list-group a:hover {
  background-color: rgb(52 97 255 / 15%);
  color: var(--secondary-color);
  transition: all 0.2s ease-out;
}

.add-post {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.15);
}

.fm-icon-box {
  font-size: 32px;
  background: #fff;
  width: 52px;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.card.radius-10.border-0.border-start.border-orange.border-3 {
  margin-bottom: 25px;
}
.back-to-top {
  cursor: pointer;
  display: none;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 26px;
  color: #fff;
  position: fixed;
  border-radius: 50%;
  bottom: 20px;
  right: 12px;
  background-color: var(--secondary-color);
  z-index: 343;
}

.back-to-top:hover {
  color: #fff;
  background-color: #000;
  transition: all 0.5s;
}

.project-user-groups img {
  margin-right: -14px;
  border: 1px solid #e4e4e4;
  padding: 2px;
  cursor: pointer;
}

.project-user-plus {
  width: 33px;
  height: 33px;
  margin-right: 0px;
  line-height: 33px;
  background: #fff;
  border-radius: 50%;
  text-align: center;
  font-size: 22px;
  cursor: pointer;
  border: 1px dotted #a9b2bb;
  color: #404142;
}

.user-groups img {
  margin-left: -14px;
  border: 1px solid #e4e4e4;
  padding: 2px;
  cursor: pointer;
}

.user-plus {
  width: 33px;
  height: 33px;
  margin-left: -14px;
  line-height: 33px;
  background: #fff;
  border-radius: 50%;
  text-align: center;
  font-size: 22px;
  cursor: pointer;
  border: 1px dotted #a9b2bb;
  color: #404142;
}

.icon-box {
  width: 60px;
  height: 60px;
  background-color: #ffffff;
  border-radius: 50%;
  border: 1px solid #c7c8cb;
  font-size: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.product-box {
  width: 52px;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  cursor: pointer;
  background-color: #ffffff;
}

.best-product-item {
  background-color: #ffffff;
  cursor: pointer;
  border-radius: 10px;
  padding: 0.5rem;
}

.best-product-item:hover {
  background-color: #f5f5f5;
}

.product-box img {
  width: 100%;
}

.best-product {
  position: relative;
  height: 420px;
}

.by-device-container {
  position: relative;
  height: 120px;
}

.chart-container1 {
  position: relative;
  height: 340px;
}

.sepration {
  height: 100px;
}

.sepration-2 {
  height: 50px;
}

.gmaps,
.gmaps-panaroma {
  height: 400px;
  background: #eee;
  border-radius: 3px;
}

.theme-icons {
  background-color: #fff;
  color: #434547;
}

.cursor-pointer {
  cursor: pointer;
}

.icon-badge {
  width: 45px;
  height: 45px;
  font-size: 1.2rem;
  background: #f2f2f2;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.product-img-2 {
  width: 45px;
  height: 45px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border: 1px solid #e6e6e6;
}

.error-social a {
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 18px;
  color: #fff;
  text-align: center;
  border-radius: 50%;
  margin: 5px;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.productlist .product-box {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.25rem;
  border: 1px solid #e2e3e4;
  background-color: #ffffff;
}

.productlist .product-box img {
  width: 100%;
}

.productlist .product-title {
  color: rgb(25, 27, 34);
}

.orderlist .product-box {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.25rem;
  border: 1px solid #e2e3e4;
  background-color: #ffffff;
}

.orderlist .product-box img {
  width: 100%;
}

.orderlist .product-title {
  color: rgb(25, 27, 34);
}

.breadcrumb-title {
  font-size: 20px;
  border-right: 1.5px solid #aaa4a4;
}

.page-breadcrumb .breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  font-family: LineIcons;
  content: "\ea5c";
}

.page-breadcrumb .breadcrumb li.breadcrumb-item {
  font-size: 16px;
}

.iconmenu .ps__rail-x {
  height: 0px;
}

.iconmenu .ps__rail-y {
  width: 0px;
}

.iconmenu .ps__rail-x.ps--clicking .ps__thumb-x,
.iconmenu .ps__rail-x:focus > .ps__thumb-x,
.iconmenu .ps__rail-x:hover > .ps__thumb-x {
  height: 5px;
}

.iconmenu .ps__rail-y.ps--clicking .ps__thumb-y,
.iconmenu .ps__rail-y:focus > .ps__thumb-y,
.iconmenu .ps__rail-y:hover > .ps__thumb-y {
  width: 5px;
}

.w_chart {
  position: relative;
  display: inline-block;
  width: 80px !important;
  height: 80px !important;
  text-align: center;
  font-size: 1.4em;
}

.w_percent {
  display: inline-block;
  line-height: 80px !important;
  z-index: 2;
  color: #0a0808;
}

.w_percent:after {
  content: "%";
  margin-left: 0.1em;
}

.w_chart canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 80px !important;
  height: 80px !important;
}

#geographic-map {
  width: 100%;
  height: 400px;
}

.new-customer-list {
  position: relative;
  height: 500px;
}

.new-customer-list .customer-list-item {
  padding: 0.5rem;
  border-radius: 10px;
}

.new-customer-list .customer-list-item:hover {
  background-color: #f0f0f0;
}

.top-sellers-list {
  position: relative;
  height: 460px;
}

.top-sellers-list .sellers-list-item {
  padding: 0.5rem;
  border-radius: 10px;
}

.top-sellers-list .sellers-list-item:hover {
  background-color: #f0f0f0;
}

.client-message {
  position: relative;
  height: 565px;
}

.client-message .client-messages-list {
  padding: 0.5rem;
}

.client-message .client-messages-list:hover {
  background-color: #f0f0f0;
}

a,
button,
a:hover,
button:hover {
  transition: all 0.3s;
}

/* Widgets */

.widget-icon {
  width: 48px;
  height: 48px;
  background-color: #fafafa;
  border-radius: 50%;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.widget-icon-large {
  width: 54px;
  height: 54px;
  background-color: #fafafa;
  border-radius: 10px;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

/* Chips */

.chip {
  display: inline-block;
  height: 32px;
  padding: 0 12px;
  margin-right: 1rem;
  margin-bottom: 1rem;
  font-size: 14px;
  font-weight: 500;
  line-height: 32px;
  color: rgba(0, 0, 0, 0.7);
  cursor: pointer;
  background-color: #f1f1f1;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 16px;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
  box-shadow: none;
}

.chip img {
  float: left;
  width: 32px;
  height: 32px;
  margin: 0 8px 0 -12px;
  border-radius: 50%;
}

.profile-cover {
  background-image: linear-gradient(
      to bottom right,
      rgb(26 30 33 / 50%),
      rgb(0 0 0 / 50%)
    )
    /* ,url(../images/gallery/33.png) */;
  background-size: cover;
  height: 24rem;
  background-position: center;
  margin: -4rem -1.5rem -5.5rem -1.5rem;
  padding: 1.5rem 1.5rem 6.5rem 1.5rem;
}

/* Email */

.email-wrapper {
  width: auto;
  height: 600px;
  overflow: hidden;
  border-radius: 0.25rem;
  position: relative;
  background: #fff;
  box-shadow: 0 0.1rem 0.7rem rgba(0, 0, 0, 0.1);
}

.email-sidebar {
  width: 250px;
  height: 100%;
  position: absolute;
  background: #fff;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 2;
  overflow: hidden;
  border-right: 1px solid rgba(0, 0, 0, 0.125);
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.email-sidebar-header {
  width: auto;
  height: auto;
  position: relative;
  background: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  border-right: 0 solid rgba(0, 0, 0, 0.125);
  border-top-left-radius: 0.25rem;
  padding: 15px;
}

.email-navigation {
  position: relative;
  padding: 0;
  height: 345px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.email-header {
  position: absolute;
  height: 70px;
  left: 250px;
  right: 0;
  top: 0;
  padding: 15px;
  background: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  border-top-right-radius: 0.25rem;
  z-index: 1;
}

.email-content {
  position: absolute;
  left: 0;
  right: 0;
  width: auto;
  top: 70px;
  height: auto;
  margin-left: 250px;
  padding: 0;
  background: #fff;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.email-navigation a.list-group-item {
  color: #404142;
  padding: 0.35rem 1.25rem;
  background-color: #fff;
  border-bottom: 1px solid rgb(0 0 0 / 0%);
  transition: all 0.3s ease-out;
}

.email-navigation a.list-group-item:hover {
  background-color: rgb(13 110 253 / 0.12);
}

.email-navigation a.list-group-item.active {
  color: #0b5ed7;
  font-weight: 600;
  background-color: rgb(13 110 253 / 0.12);
}

.email-meeting {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}

.email-meeting a.list-group-item {
  color: #404142;
  padding: 0.35rem 1.25rem;
  background-color: #fff;
  border-bottom: 1px solid rgb(0 0 0 / 0%);
}

.email-meeting a.list-group-item:hover {
  background-color: rgb(0 123 255 / 15%);
  transition: all 0.3s ease-out;
}

.email-hangout .chat-user-online:before {
  content: "";
  position: absolute;
  bottom: 8px;
  left: 45px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  box-shadow: 0 0 0 2px #fff;
  background: #16e15e;
}

.email-toggle-btn {
  width: auto;
  height: auto;
  margin-right: 10px;
  text-align: center;
  font-size: 24px;
  color: #404142;
  border-radius: 0;
  cursor: pointer;
  background-color: #fff;
  border: 0 solid rgb(0 0 0 / 15%);
}

.email-actions {
  width: 230px;
}

.email-time {
  font-size: 13px;
  color: #6c757d;
}

.email-list div.email-message {
  background: #fff;
  border-bottom: 1px solid rgb(0 0 0 / 8%);
  color: #383a3c;
}

.email-list div.email-message:hover {
  transition: all 0.2s ease-out;
  background-color: #eceef1;
}

.email-list {
  position: relative;
  height: 530px;
}

.email-star {
  color: #6c757d;
}

.email-read-box {
  position: relative;
  height: 530px;
}

.compose-mail-popup {
  width: 42%;
  position: fixed;
  bottom: -30px;
  right: 30px;
  z-index: 15;
  display: none;
}

.compose-mail-toggled {
  display: block;
}

.compose-mail-title {
  font-size: 16px;
}

.compose-mail-close {
  width: 25px;
  height: 25px;
  line-height: 25px;
  text-align: center;
  font-size: 14px;
  border-radius: 2px;
  background-color: rgb(255 255 255 / 0%);
}

.compose-mail-close:hover {
  background-color: rgb(255 255 255 / 20%);
}

/* chat box */

.chat-wrapper {
  width: auto;
  height: 600px;
  border-radius: 0.25rem;
  position: relative;
  background: #fff;
  box-shadow: 0 0.1rem 0.7rem rgba(0, 0, 0, 0.1);
}

.chat-sidebar {
  width: 340px;
  height: 100%;
  position: absolute;
  background: #fff;
  left: 0;
  top: 0;
  bottom: 0;
  z-index: 2;
  overflow: hidden;
  border-right: 1px solid rgba(0, 0, 0, 0.125);
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.chat-sidebar-header {
  width: auto;
  height: auto;
  position: relative;
  background: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  border-right: 0 solid rgba(0, 0, 0, 0.125);
  border-top-left-radius: 0.25rem;
  padding: 15px;
}

.chat-sidebar-content {
  padding: 0;
}

.chat-user-online {
  position: relative;
}

.chat-sidebar-header .chat-user-online:before {
  content: "";
  position: absolute;
  bottom: 7px;
  left: 40px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  box-shadow: 0 0 0 2px #fff;
  background: #16e15e;
}

.chat-list .chat-user-online:before {
  content: "";
  position: absolute;
  bottom: 7px;
  left: 36px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  box-shadow: 0 0 0 2px #fff;
  background: #16e15e;
}

.chat-content {
  margin-left: 340px;
  padding: 85px 15px 15px 15px;
}

.chat-header {
  position: absolute;
  height: 70px;
  left: 340px;
  right: 0;
  top: 0;
  padding: 15px;
  background: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  border-top-right-radius: 0.25rem;
  z-index: 1;
}

.chat-footer {
  position: absolute;
  height: 70px;
  left: 340px;
  right: 0;
  bottom: 0;
  padding: 15px;
  background: #f8f9fa;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
  border-bottom-right-radius: 0.25rem;
}

.chat-footer-menu a {
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 18px;
  color: #6c757d;
  text-align: center;
  border-radius: 50%;
  margin: 3px;
  background-color: #fff;
  border: 1px solid rgb(0 0 0 / 15%);
}

.chat-tab-menu li a.nav-link {
  padding: 0.3rem 0.2rem;
  line-height: 1.2;
  color: #4a4b4c;
}

.chat-tab-menu .nav-pills .nav-link.active,
.chat-tab-menu .nav-pills .show > .nav-link {
  color: #008cff;
  background-color: rgb(0 123 255 / 0%);
}

.chat-title {
  font-size: 14px;
  color: #272b2f;
}

.chat-msg {
  font-size: 13px;
  color: #6c757d;
}

.chat-time {
  font-size: 13px;
  color: #6c757d;
}

.chat-list {
  position: relative;
  height: 300px;
}

.chat-list .list-group-item {
  border: 1px solid rgb(0 0 0 / 0%);
  background-color: transparent;
}

.chat-list .list-group-item:hover {
  border: 1px solid rgb(0 0 0 / 0%);
  background-color: rgb(13 110 253 / 0.12);
}

.chat-list .list-group-item.active {
  background-color: rgb(13 110 253 / 0.12);
}

.chart-online {
  color: #16e15e;
}

.chat-top-header-menu a {
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 18px;
  color: #6c757d;
  text-align: center;
  border-radius: 50%;
  margin: 3px;
  background-color: #fff;
  border: 1px solid rgb(0 0 0 / 15%);
}

.chat-content {
  position: relative;
  width: auto;
  height: 520px;
}

.chat-content-leftside .chat-left-msg {
  width: fit-content;
  background-color: #eff2f5;
  padding: 0.8rem;
  border-radius: 12px;
  max-width: 480px;
  text-align: left;
  border-top-left-radius: 0;
}

.chat-content-rightside .chat-right-msg {
  width: fit-content;
  background-color: #dcedff;
  padding: 0.8rem;
  border-radius: 12px;
  float: right;
  max-width: 480px;
  text-align: left;
  border-bottom-right-radius: 0;
}

.chat-toggle-btn {
  width: 40px;
  height: 40px;
  line-height: 40px;
  margin-right: 15px;
  text-align: center;
  font-size: 24px;
  color: #6c757d;
  border-radius: 50%;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid rgb(0 0 0 / 15%);
}

/* Authentication */

.authentication-card {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  max-width: 60rem;
  height: 100vh;
}

.login-separater span {
  position: relative;
  top: 26px;
  margin-top: -10px;
  background: #fff;
  padding: 5px;
  font-size: 12px;
  color: #cbcbcb;
  z-index: 1;
}

/* swicther */

.btn-switcher {
  position: fixed;
  right: 0px;
  top: 40%;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.header-colors-indigators .indigator {
  width: 50px;
  height: 50px;
  background-color: #f7f8fa;
  border-radius: 10px;
  cursor: pointer;
}

/* Responsive */

@media screen and (max-width: 1280px) {
  .email-header {
    height: auto;
  }
  .email-content {
    padding: 100px 0 0 0;
  }
}

@media screen and (max-width: 1024px) {
  .sidebar-wrapper {
    left: -300px;
  }

  .page-content {
    margin-left: 0px;
  }

  .top-header .navbar {
    left: 0px;
  }

  /* Toggeled */

  .wrapper.toggled .sidebar-wrapper {
    left: 0px;
  }

  .wrapper.toggled .page-content {
    margin-left: 0px;
  }

  .wrapper.toggled .top-header .navbar {
    left: 0px;
  }

  .wrapper.toggled .overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #000;
    opacity: 0.6;
    z-index: 12;
    display: block;
    cursor: move;
    transition: all 0.2s ease-out;
  }

  .top-header .navbar .searchbar {
    position: relative;
    width: 30%;
    display: none;
  }

  .top-header .navbar .full-searchbar {
    position: absolute;
    top: 0%;
    left: 0;
    right: 0%;
    width: 100%;
    height: 60px;
    background-color: #ffffff;
    border-bottom: 1px solid rgb(0 0 0 / 0%);
    background-clip: padding-box;
    display: flex !important;
    z-index: 1;
    align-items: center;
    justify-content: flex-start;
    padding: 0 1rem;
    -webkit-animation: 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards
      1 animdropdown;
    animation: 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards 1
      animdropdown;
  }

  .email-header {
    border-top-left-radius: 0.25rem;
  }
  .email-sidebar {
    left: -280px;
  }
  .email-content {
    margin-left: 0;
  }
  .email-header {
    left: 0;
  }
  .email-toggled .email-sidebar {
    left: 0;
  }
  .email-toggled .overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 250px;
    background: #000;
    opacity: 0.5;
    z-index: 9;
    display: block;
    cursor: move;
    transition: all 0.3s ease-out;
  }

  .compose-mail-popup {
    width: auto;
    position: fixed;
    bottom: -30px;
    right: 0;
    left: 0;
  }

  .chat-header {
    border-top-left-radius: 0.25rem;
  }
  .chat-footer {
    border-bottom-left-radius: 0.25rem;
  }
  .chat-sidebar {
    left: -370px;
  }
  .chat-content {
    margin-left: 0;
  }
  .chat-header {
    left: 0;
  }
  .chat-footer {
    left: 0;
  }
  .chat-toggled .chat-sidebar {
    left: 0;
  }
  .chat-toggled .overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 340px;
    background: #000;
    opacity: 0.5;
    z-index: 11;
    display: block;
    cursor: move;
    transition: all 0.3s ease-out;
  }

  .authentication-card {
    padding: 1.5rem 0;
    height: auto;
  }

  .top-header .navbar .mobile-toggle-icon,
  .top-header .navbar .search-toggle-icon {
    display: block;
  }

  .top-header .navbar .searchbar .search-close-icon {
    display: block;
  }
}

@media screen and (max-width: 767px) {
  .top-header .navbar .dropdown-menu::after {
    display: none;
  }

  .top-header .navbar .dropdown {
    position: static !important;
  }

  .top-header .navbar .dropdown-menu {
    width: 100% !important;
  }

  .by-device-container {
    height: 210px;
  }
}

@media screen and (max-width: 520px) {
  .chat-footer-menu,
  .chat-top-header-menu {
    display: none;
  }
}

/*custom css by designer */

:focus {
  box-shadow: none !important;
}

/* customer-index pagee css custom start from here  */

.customer-header.top-header .navbar {
  left: 0;
  height: 75px;
}

.page-content.customer-contnent {
  margin-left: 0;
  margin-top: 75px;
  padding: 1.5rem;
  transition: all 0.2s;
}

.custom-logo img {
  width: 140px;
}

.user-changepass {
  height: 58px;
  padding: 5px;
  background: var(--primary-color);
  color: white;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 205px;
  /* width: 65%; */
  margin-left: auto;
}

.header-username {
  font-size: 14px;
  font-weight: 600;
  width: 100%;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.add-space-btns button,
.add-space-btns a {
  margin-right: 5px;
}

.change-pass-btn,
.logout-btn {
  color: white;
  font-size: 13px;
  padding: 0;
  background: transparent;
  border: 0;
  outline: 0;
}
.card-header.py-3 h5.mb-0.font-22 {
  text-transform: capitalize;
}
.open-model-btn {
  padding: 0;
  background: transparent;
  border: 0;
  outline: 0;
}
.change-pass-btn {
  margin-right: 5px;
  padding: 0;
  background: transparent;
  border: 0;
  outline: 0;
  color: white;
}
.text-info-bold {
  color: #32bfff !important;
  font-weight: 700;
}

.btn-primary {
  color: #fff;
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  box-shadow: none;
}
.btn-primary:hover,
.btn-primary:focus .btn-check:checked + .btn,
.btn-primary.active,
.btn.show,
.btn-primary:first-child:active,
:not(.btn-check) + .btn-primary:active,
.btn:focus-visible {
  color: #fff;
  background-color: var(--secondary-color);
  border-color: var(--secondary-color);
  box-shadow: none;
}

.btn-primary.disabled,
.btn-primary:disabled,
fieldset:disabled .btn-primary {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.btn-primary:disabled .spinner-border {
  display: inline-block;
  border: 3px solid;
  border-right-color: transparent;
  width: 17px;
  height: 17px;
  margin-right: 7px;
}

.form-check-input:checked {
  background-color: var(--secondary-color);
  border-color: var(--secondary-color);
}

.btn-check:active + .btn-primary:focus,
.btn-check:checked + .btn-primary:focus,
.btn-primary.active:focus,
.btn-primary:active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}

.css-1xc3v61-indicatorContainer {
  color: hsl(0deg 0% 59.99%) !important;
}

.css-1jqq78o-placeholder {
  color: #212529 !important;
}

.css-t3ipsp-control,
.form-control:focus,
.form-select:focus {
  color: var(--primary-color) !important;
  background-color: #fff;
  border-color: var(--primary-color) !important;
  outline: 0;
  box-shadow: none !important;
}

.css-1dimb5e-singleValue {
  color: var(--primary-color) !important;
  font-size: 1rem;
  font-weight: 400;
}

.dark-border:focus,
.dark-border .css-t3ipsp-control {
  border: thick solid rgb(0, 0, 255) !important;
  border-color: rgb(0, 0, 255) !important;
}

.modal-xxl {
  max-width: 90%;
}

div#add_customer_model,
div#delete-inner-model {
  z-index: 11111111111111111111111111111111111;
  background: #00000078;
}

.upload-osr-file {
  width: 55%;
  margin: 0 auto;
}

.search-certificate-label {
  align-items: center;
  /* justify-content: center; */
  display: flex;
  margin-right: 10px;
}

.search-certificate-result td {
  vertical-align: middle;
}
.search-certificate-result td,
.search-certificate-result th {
  padding-left: 10px;
}

#sidebar {
  width: 15%;
  position: fixed;
  left: 0;
  bottom: 0;
  top: 73px;
  height: calc(100vh + -75px);
  transition: all 0.12s;
  overflow-y: auto;
  background: white;
  z-index: 222;
}
.mbj {
  max-width: 100%;
  height: 70px;
  margin-right: 3px;
}
img.card_logo_img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  height: 90px;
  /* margin-right: 3px; */
  padding-bottom: 18px;
}
/* width */
#sidebar::-webkit-scrollbar {
  width: 3px;
}

/* Handle */
#sidebar::-webkit-scrollbar-thumb {
  background: var(--primary-color);
}

/* Handle on hover */
#sidebar::-webkit-scrollbar-thumb:hover {
  background: var(--secondary-color);
}

div#sidebar-nav {
  min-height: auto !important;
}

.card-logo-img {
  width: 130px;
}

.card-logo-table td {
  vertical-align: middle;
}

#sidebar.collapse:not(.show) {
  width: 0%;
  overflow: hidden;
  transition: all 0.12s;
  display: block !important;
}

.site-main-design {
  width: 84%;
  margin-left: auto;
  transition: all 0.5s;
  padding-bottom: 50px;
}

.site-main-design.full-show {
  width: 100%;
  transition: all 0.5s;
}

#sidebar .list-group-item:hover,
#sidebar .list-group-item.active,
#sidebar .list-group-item:focus {
  color: white;
  background: var(--primary-color);
  border-color: var(--primary-color);
}

#menu-open-close {
  background: var(--primary-color);
  color: white;
  padding: 5px 8px !important;
  font-size: 16px;
  line-height: initial;
}
.bg-orange {
  background-color: var(--secondary-color) !important;
}

.border-orange {
  border-color: var(--secondary-color) !important;
}

.manage-btns {
  color: #ffffff;
  margin-right: 7px;
  margin-top: 10px;
  display: inline-block;
  border: 1px dotted var(--primary-color);
  padding: 3px 7px;
  border-radius: 3px;
  font-size: 13px;
  background: var(--primary-color);
}

.manage-btns:hover {
  background-color: var(--secondary-color) !important;
  border-color: var(--secondary-color) !important;
  color: white;
}

.site-footer-main {
  position: fixed;
  left: 15%;
  right: 0;
  width: 100%;
  bottom: 0;
  text-align: center;
  padding: 20px;
  width: calc(100% - 15%);
  transition: all 0.2s;
  border-top: 1px solid #dadada;
  background: white;
  z-index: 222;
}
.site-footer-main.full-show {
  width: 100%;
  transition: all 0.2s;
  left: 0;
}

.color-enable {
  position: relative;
  padding: 30px;
  padding-top: 60px;
}

.loockup-table td,
.mail-manage td,
.vr-mid td {
  vertical-align: middle;
}

.certificate-details td a {
  display: block;
  color: #000;
}

.color-enable::before {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  content: "";
  opacity: 0.5;
  display: block;
  z-index: 1;
  background: rgb(0, 0, 0);
  transition: all 0.5s;
}

.color-enable.enab-color::before {
  z-index: -1;
  background: white;
  transition: all 0.5s;
}

.enablecolor-div {
  position: absolute;
  left: 15px;
  top: 10px;
  z-index: 11;
}

.enablecolor-div label {
  color: white;
}
.color-enable.enab-color .enablecolor-div label {
  color: initial;
}
#gradX {
  margin: 38px 34% 44px 2% !important;
}
div#gradx_add_slider {
  color: #fff !important;
  background: #3e3934 !important;
  padding: 5px 12px !important;
}
.gradx_btn i.icon.icon-add {
  display: none;
}

.gradx {
  width: 470px !important;
}

.targets {
  display: flex;
  justify-content: space-evenly;

  margin: 0 auto;
  text-align: center;
  border: 1px solid #ccc;
  background: #f8f8f8;
  margin: 0 auto;
  border-radius: 4px;
  width: auto;
  padding: 0px 15px;
}

.target1 {
  border: 1px solid;
  margin: 0px 10%;
  width: 150px;
  height: 150px;
  display: inline-block;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.target2 {
  border: 1px solid;
  margin: 0px 10%;
  width: 150px;
  height: 150px;
  display: inline-block;
  display: flex;
  align-items: center;
  justify-content: center;
}

.targets span {
  background: #f8f8f8;
  border: 1px solid #ddd;
  padding: 2px;
  border-radius: 2px;
  font-size: 14px;
}

/* pre loader css  */
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background: #ffffffb3;
  z-index: 1111111111111111111;
  display: none;
}

.lds-facebook {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-facebook div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: var(--primary-color);
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds-facebook div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}
.lds-facebook div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}
.lds-facebook div:nth-child(3) {
  left: 56px;
  animation-delay: 0;
}
@keyframes lds-facebook {
  0% {
    top: 8px;
    height: 64px;
  }
  50%,
  100% {
    top: 24px;
    height: 32px;
  }
}

.error {
  color: red !important;
}

.inner-job-table {
  width: 100%;
  border: 1px solid #3e3b3b;
}
.inner-job-table tr {
  background: white;
}
.inner-job-table tr:nth-child(2n + 1) {
  background-color: #f2f2f2;
}

.inner-job-table tr td {
  padding: 8px 10px;
}

.on-off-cards .form-switch {
  padding: 0;
}
.on-off-cards .form-switch .form-check-input {
  margin: 0 auto;
  display: block;
  float: none;
  width: 40px;
  height: 20px;
}

.on-off-cards .form-switch label {
  line-height: 2.5;
  font-size: 16px;
  width: 60%;
  display: block;
  margin: 0 auto;
  margin-bottom: 35px;
}

.change-color-this {
  background-color: none;
  background: black;
}

select#ceckstatus {
  color: white !important;
  display: block;
  width: 100%;
  padding: 0.45rem 2.25rem 0.45rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.sort-ths th {
  position: relative;
  cursor: pointer;
  padding-right: 25px;
}

.sort-ths th::after {
  right: 0.5em;
  content: "↓";
  position: absolute;
  bottom: 0.9em;
  display: block;
  opacity: 0.3;
}

.sort-ths th::before {
  right: 1em;
  content: "↑";
  position: absolute;
  bottom: 0.9em;
  display: block;
  opacity: 0.3;
}

.sort-ths th.ascending::before {
  opacity: 1;
}

.sort-ths th.descending::after {
  opacity: 1;
}

.wrap-colors {
  position: relative;
  margin-top: 20px;
  padding-top: 1px;
}

.change-color {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0px;
  opacity: 0.5;

  background: rgb(0, 0, 0);
}

div.checkbox {
  position: absolute;
  color: #fff;
  top: 0;
  margin-left: 20px;
}

.c-color {
  color: rgb(255, 255, 255);
}

@media (min-width: 1200px) and (max-width: 1311px) {
  .card.radius-10.border-0.border-start.border-orange.border-3 .card-body {
    padding: 1rem 0.5rem;
  }

  .manage-btns {
    color: #ffffff;
    margin-right: 2px;
    margin-top: 5px;
    display: inline-block;
    border: 1px dotted var(--primary-color);
    padding: 3px 5px;
    border-radius: 3px;
    font-size: 13px;
    background: var(--primary-color);
  }

  .card.radius-10.border-0.border-start.border-orange.border-3
    .card-body
    .widget-icon {
    width: 40px;
    height: 40px;
  }

  .card.radius-10.border-0.border-start.border-orange.border-3
    .card-body
    h4.mb-1 {
    font-size: 22px;
  }

  .on-off-cards .form-switch label {
    width: 80%;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .upload-osr-file {
    width: 90%;
    margin: 0 auto;
  }

  .search-certificate-result button.btn.btn-primary {
    font-size: 14px;
  }

  .on-off-cards .form-switch label {
    width: 80%;
  }

  #sidebar a.list-group-item {
    font-size: 12px;
    padding: 5px;
  }

  .card.radius-10.border-0.border-start.border-orange.border-3
    .card-body
    h4.mb-1 {
    font-size: 22px;
  }

  .card.radius-10.border-0.border-start.border-orange.border-3
    .card-body
    h4.mb-1
    .widget-icon {
    width: 50px;
    height: 44px;
  }

  .manage-btns {
    color: #ffffff;
    margin-right: 9px;
    margin-top: 10px;
    display: inline-block;
    border: 1px dotted var(--primary-color);
    padding: 3px 9px;
    border-radius: 3px;
    font-size: 12px;
    background: var(--primary-color);
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  ul.pagination {
    justify-content: initial;
  }
  .upload-osr-file {
    width: 100%;
    margin: 0 auto;
  }
  .search-certificate-result button.btn.btn-primary {
    font-size: 12px;
  }

  #sidebar {
    width: 0%;
    z-index: 111111;
  }

  .on-off-cards .form-switch label {
    width: 70%;
  }

  #sidebar.collapse:not(.show) {
    width: 35%;
    overflow-y: auto;
    z-index: 555;
  }

  #menu-open-close {
    float: right;
  }
  .site-main-design {
    width: 100%;
  }

  .site-footer-main {
    width: 100%;
    left: 0;
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .modal-xxl .modal-content {
    width: 100% !important;
  }
  ul.pagination {
    justify-content: initial;
  }
  .mb-mob {
    margin-bottom: 10px;
  }
  .card-header h5.mb-0 .btn-primary {
    float: none !important;
    display: block !important;
    margin-top: 5px;
    margin-left: auto;
  }
  .custom-logo img {
    width: 120px;
  }
  .user-changepass {
    width: 190px;
  }
  .site-footer-main {
    width: 100%;
    left: 0;
  }

  .on-off-cards .form-switch label {
    width: 50%;
  }

  .comp-name,
  .card-body a.btn.btn-primary.btn-sm {
    margin-top: 10px;
  }

  .text-white.font-20,
  .card-header.py-3 h5.mb-0.font-22 {
    font-size: 16px;
  }

  .upload-osr-file {
    width: 100%;
    margin: 0 auto;
  }
  .card-header.py-3 h5.mb-0.font-35 {
    font-size: 22px !important;
  }

  .search-certificate-result .input-group label {
    display: block;
    width: 100%;
  }

  .search-certificate-result .btn.btn-primary {
    font-size: 12px;
  }

  .dataTables_length {
    margin-bottom: 10px;
  }

  .site-main-design {
    width: 100%;
  }
  #sidebar {
    width: 0%;
    z-index: 111;
  }

  #menu-open-close {
    float: right;
  }

  #sidebar.collapse:not(.show) {
    width: 70%;
    overflow-y: auto;
    z-index: 555;
  }

  .targets {
    display: block;
  }

  .target1,
  .target2 {
    margin: 20px auto;
  }
}

@media (min-width: 320px) and (max-width: 575px) {
  .manage-btns {
    color: #ffffff;
    margin-right: 7px;
    margin-top: 10px;
    display: block;
    border: 1px dotted var(--primary-color);
    padding: 3px 7px;
    border-radius: 3px;
    font-size: 12px;
    background: var(--primary-color);
    max-width: 130px;
    width: fit-content;
    text-align: center;
  }
  .col-12 button.btn.btn-primary.float-end.mt-2 {
    float: none !important;
    width: 100%;
    display: block;
    margin-top: 20px !important;
  }
}

@media (min-width: 320px) and (max-width: 367px) {
  .custom-logo img {
    width: 100px;
  }
  .user-changepass {
    width: 173px;
    padding: 3px;
    height: 51px;
  }

  .on-off-cards .form-switch label {
    width: 60%;
  }
}

/* customer-index pagee css custom end here  */

html.light-theme .sidebar-wrapper .metismenu .mm-active > a,
html.light-theme .sidebar-wrapper .metismenu a:active,
html.light-theme .sidebar-wrapper .metismenu a:focus,
html.light-theme .sidebar-wrapper .metismenu a:hover {
  color: #3461ff;
  text-decoration: none;
  background-color: rgb(52 97 255 / 10%);
  border-left: 4px solid #3461ff;
  box-shadow: none;
}

html.light-theme .sidebar-wrapper {
  background-color: #ffffff;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%);
}

html.light-theme .sidebar-wrapper .sidebar-header {
  background-color: #ffffff;
}

html.light-theme .top-header .navbar {
  background-color: #ffffff;
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%);
}

html.light-theme .top-header .navbar .searchbar .form-control {
  background-color: #f9fafb;
}

html.light-theme .top-header .navbar .searchbar .form-control:focus {
  background-color: #ffffff;
  border-color: #86b7fe;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

html.dark-theme body {
  color: #fcfcfc;
  background-color: #1a2232;
}

html.dark-theme ::placeholder {
  color: #fff !important;
  opacity: 0.4 !important;
}

/* Sidebar */

html.dark-theme .sidebar-wrapper,
html.dark-theme .sidebar-wrapper .sidebar-header {
  background-color: #1a2232;
}

html.dark-theme .sidebar-wrapper,
html.dark-theme .sidebar-wrapper .sidebar-header {
  border-right: 1px solid rgb(255 255 255 / 12%);
}

html.dark-theme .sidebar-wrapper .sidebar-header {
  border-bottom: 1px solid rgb(255 255 255 / 12%);
  color: #fcfcfc;
}

html.dark-theme .sidebar-wrapper .sidebar-header .logo-text,
html.dark-theme .sidebar-wrapper .sidebar-header .toggle-icon {
  color: #fcfcfc;
}

html.dark-theme .sidebar-wrapper .metismenu .mm-active > a,
html.dark-theme .sidebar-wrapper .metismenu a:active,
html.dark-theme .sidebar-wrapper .metismenu a:focus,
html.dark-theme .sidebar-wrapper .metismenu a:hover {
  color: #fff;
  text-decoration: none;
  background: rgb(46 57 78);
  border-left: 4px solid #ffffff;
}

html.dark-theme .sidebar-wrapper .metismenu a {
  color: #9ea4aa;
}

html.dark-theme .sidebar-wrapper .metismenu ul {
  border: 1px solid #ffffff00;
}

html.dark-theme .sidebar-wrapper .sidebar-header img {
  filter: invert(1) grayscale(100%) brightness(200%);
}

html.dark-theme .sidebar-wrapper .simplebar-scrollbar:before {
  background: rgba(255, 255, 255, 0.4);
}

/* Header */

html.dark-theme .top-header .navbar {
  color: #fcfcfc;
  background-color: #1a2232;
  border-bottom: 1px solid rgb(255 255 255 / 12%);
}

html.dark-theme .top-header .navbar .custom-logo img {
  filter: contrast(0) brightness(1000%);
}

html.dark-theme .top-header .navbar .top-navbar-right .nav-link {
  color: #fcfcfc;
}

html.dark-theme .top-header .navbar .mobile-toggle-icon,
html.dark-theme .top-header .navbar .search-toggle-icon {
  color: #fcfcfc;
}

html.dark-theme .top-header .navbar .searchbar .form-control {
  color: #fcfcfc;
  background-color: #202a40;
  border: 1px solid rgb(255 255 255 / 12%);
}

/* Components */

html.dark-theme .btn-dark {
  color: #fff;
  background-color: #101821;
  border-color: #101821;
}

html.dark-theme .btn-outline-dark {
  color: #b1b6bb;
  border-color: #7f868d;
}

html.dark-theme .btn-white {
  color: #ffffff;
  background-color: #202a40;
  border-color: rgb(255 255 255 / 12%);
}

html.dark-theme .btn-light {
  color: #fff;
  background-color: #313c53;
  border-color: rgb(255 255 255 / 12%);
}

html.dark-theme .ff_fileupload_wrap .ff_fileupload_dropzone,
html.dark-theme .imageuploadify {
  border: 2px dashed rgb(255 255 255 / 74%);
  background-color: #202a40;
}

html.dark-theme .picker__input {
  background-color: #1a2232 !important;
}

html.dark-theme .picker__holder {
  background: #1a2232;
  border: 1px solid rgb(255 255 255 / 12%);
}

html.dark-theme .picker__button--today,
html.dark-theme .picker__button--clear,
html.dark-theme .picker__button--close {
  border: 1px solid rgb(255 255 255 / 12%);
  background: #1a2232;
}

html.dark-theme .select2-container--bootstrap4 .select2-selection {
  background-color: #1a2232;
  border: 1px solid rgb(255 255 255 / 0.35);
}
html.dark-theme .select2-dropdown {
  background-color: #1a2232;
  border: 1px solid rgb(255 255 255 / 38%);
}
html.dark-theme .select2-search--dropdown .select2-search__field {
  border: 1px solid rgb(255 255 255 / 34%);
  background-color: #1a2232;
  color: #e3dfdf;
}
html.dark-theme
  .select2-container--bootstrap4
  .select2-selection--multiple
  .select2-selection__choice {
  color: #dee1e5;
  border: 1px solid rgb(255 255 255 / 55%);
  background: #171717;
}
html.dark-theme
  .select2-container--bootstrap4
  .select2-selection--multiple
  .select2-selection__rendered
  .select2-search__field {
  background: 0 0;
  color: #e6e1e1;
}
html.dark-theme
  .select2-container--bootstrap4
  .select2-dropdown
  .select2-results__option[aria-selected="true"] {
  background-color: #1a2232;
}

html.dark-theme .modal-content {
  background-color: #202a40;
}

html.dark-theme .modal-header {
  border-bottom: 1px solid rgb(255 255 255 / 12%);
}

html.dark-theme .modal-footer {
  border-top: 1px solid rgb(255 255 255 / 12%);
}

html.dark-theme .nav-tabs .nav-item.show .nav-link,
html.dark-theme .nav-tabs .nav-link.active {
  background-color: #1a2232;
}

html.dark-theme .nav-tabs {
  border-bottom: 1px solid rgb(255 255 255 / 12%);
}

html.dark-theme .nav-primary.nav-tabs .nav-link.active {
  border-color: #008cff #008cff rgb(255 255 255 / 24%);
}

html.dark-theme .nav-tabs .nav-link {
  color: #ffffff;
}

html.dark-theme .nav-danger.nav-tabs .nav-link.active {
  border-color: #f41127 #f41127 rgb(255 255 255 / 24%);
}

html.dark-theme .nav-success.nav-tabs .nav-link.active {
  border-color: #17a00e #17a00e rgb(255 255 255 / 24%);
}

html.dark-theme .nav-warning.nav-tabs .nav-link.active {
  border-color: #ffc107 #ffc107 rgb(255 255 255 / 24%);
}

html.dark-theme .nav-pills .nav-link {
  color: #ffffff;
}

html.dark-theme .page-link {
  color: #ffffff;
  background-color: #202a40;
  border: 1px solid rgb(255 255 255 / 12%);
}

html.dark-theme .page-item.disabled .page-link {
  color: #ffffff;
  background-color: #181f2e;
  border-color: rgb(255 255 255 / 12%);
}

html.dark-theme .productlist .product-box {
  border: 1px solid rgb(255 255 255 / 12%);
  background-color: #1a2232;
}

html.dark-theme .form-control,
html.dark-theme .form-select {
  color: #fcfcfc;
  background-color: #1a2232;
  border: 1px solid rgb(255 255 255 / 12%);
}

html.dark-theme .form-control:disabled,
html.dark-theme .form-control[readonly] {
  background-color: #2c364a;
}

html.dark-theme .form-select:disabled {
  background-color: #2c364a;
}

html.dark-theme .bootstrap-tagsinput {
  color: #fcfcfc;
  background-color: #1a2232;
  border: 1px solid rgb(255 255 255 / 12%);
}

html.dark-theme .input-group-text {
  color: #ffffff;
  background-color: #313c53;
  border: 1px solid rgb(255 255 255 / 12%);
}

html.dark-theme .chip {
  color: #ffffff;
  background-color: #141b29;
}

html.dark-theme .card {
  background-color: #202a40;
}

html.dark-theme .card-header,
html.dark-theme .card-footer {
  background-color: #161d2d;
  padding-left: 15px !important;
  padding-right: 15px !important;
}
html.dark-theme .top-header .navbar .user-changepass {
  background: #202a40 !important;
}

html.dark-theme .btn-primary {
  color: #fff;
  background-color: #1a2232;
  border-color: #161d2d;
  box-shadow: none;
}

html.dark-theme .site-footer-main,
html.dark-theme #sidebar {
  background: #202a40;
}
html.dark-theme #menu-open-close {
  background: #202a40;
}

html.dark-theme #sidebar .list-group-item:hover,
html.dark-theme #sidebar .list-group-item.active,
html.dark-theme #sidebar .list-group-item:focus {
  color: white;
  background: #1a2232;
  border-color: #1a2232;
}

html.dark-theme a#menu-open-close {
  background: #202a40;
  color: white;
  padding: 5px 8px !important;
  font-size: 16px;
}

html.dark-theme .manage-btns {
  color: #ffffff;
  border: 1px dotted #1a2232;
  background: #1a2232;
}

html.dark-theme .inner-job-table tr:nth-child(2n + 1) {
  background-color: #161d2d;
}
html.dark-theme .inner-job-table tr {
  background: #202a40;
}

html.dark-theme .certificate-details td a {
  color: #fcfcfc;
}

html.dark-theme select#ceckstatus {
  background: #1a2232;
  border: 1px solid rgb(255 255 255 / 12%);
}

html.dark-theme .manage-btns:hover {
  background-color: #ffffff !important;
  border-color: #ffffff !important;
  color: #1a2232;
}

html.dark-theme .bg-orange {
  background-color: #1a2232 !important;
}

html.dark-theme .btn-primary:hover,
html.dark-theme .btn-primary:focus {
  color: #1a2232;
  background-color: #fff;
  border-color: #fff;
  box-shadow: none;
}

html.dark-theme .bg-dark {
  background-color: #101821 !important;
}

html.dark-theme .text-secondary {
  color: #8c949c !important;
}

html.dark-theme .border {
  border: 1px solid rgb(255 255 255 / 12%) !important;
}

html.dark-theme .border-start {
  border-left: 1px solid rgb(255 255 255 / 12%) !important;
}

html.dark-theme .border-end {
  border-right: 1px solid rgb(255 255 255 / 12%) !important;
}

html.dark-theme .border-top {
  border-top: 1px solid rgb(255 255 255 / 12%) !important;
}

html.dark-theme .border-bottom {
  border-bottom: 1px solid rgb(255 255 255 / 12%) !important;
}

html.dark-theme .text-option {
  color: #fcfcfc !important;
}

html.dark-theme .offcanvas {
  background-color: #1a2232;
}

html.dark-theme .btn-close {
  filter: invert(1) grayscale(100%) brightness(200%);
}

html.dark-theme .w_percent {
  color: #ffffff;
}

html.dark-theme .bg-light {
  background-color: #1b2336 !important;
}

html.dark-theme .list-group-item {
  color: #fcfcfc;
  background-color: #202a40;
  border: 1px solid rgb(255 255 255 / 12%);
}

html.dark-theme .list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}

html.dark-theme .new-customer-list .customer-list-item:hover {
  background-color: #1b2436;
}

html.dark-theme .best-product-item {
  background-color: #202a40;
}
html.dark-theme .best-product-item:hover,
html.dark-theme .sellers-list-item:hover {
  background-color: #1b2436;
}

html.dark-theme .theme-icons,
html.dark-theme .product-box {
  background-color: #202a40;
  color: #f1f4f6;
}

html.dark-theme .accordion-button {
  color: #ffffff;
  background-color: #202a40;
}

html.dark-theme .accordion-item {
  background-color: #1b2436;
  border: 1px solid rgb(255 255 255 / 12%);
}

html.dark-theme .accordion-button:focus {
  border-color: #ffffff;
  box-shadow: 0 0 0 0.25rem rgb(255 255 255 / 25%);
}

html.dark-theme .accordion-button:not(.collapsed) {
  color: #ffffff;
  background-color: #161d2c;
}

html.dark-theme .dropdown-menu {
  color: #fcfcfc;
  background-color: #202a40;
}

html.dark-theme .dropdown-item {
  color: #fcfcfc;
}

html.dark-theme .top-header .navbar .dropdown-large .dropdown-menu::after,
html.dark-theme
  .top-header
  .navbar
  .dropdown-user-setting
  .dropdown-menu::after {
  background: #202a40;
  border-top: 1px solid #2f3b55;
  border-left: 1px solid #2f3b55;
}

html.dark-theme .top-header .navbar .dropdown-searchbar .form-control {
  color: #fcfcfc;
  background-color: #202a40;
}

html.dark-theme .top-header .navbar .dropdown-item .setting-icon {
  background-color: #2b3650;
}

html.dark-theme .top-header .navbar .dropdown-large .dropdown-item:hover,
html.dark-theme
  .top-header
  .navbar
  .dropdown-user-setting
  .dropdown-item:hover {
  border-radius: 10px;
  color: #fcfcfc;
  background-color: #2e384e;
}

html.dark-theme .top-header .navbar .dropdown-menu .notification-box {
  background-color: #2e384e;
}

html.dark-theme .top-header .navbar .dropdown-menu .apps:hover {
  background-color: #2e384e;
  transition: all 0.2;
}

html.dark-theme .top-header .navbar .dropdown-menu .apps .apps-name {
  color: #fcfcfc;
}

html.dark-theme .table {
  --bs-table-bg: transparent;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: #ffffff;
  --bs-table-striped-bg: rgba(255, 255, 255, 0.05);
  --bs-table-active-color: #ffffff;
  --bs-table-active-bg: rgb(255 255 255 / 10%);
  --bs-table-hover-color: #ffffff;
  --bs-table-hover-bg: rgb(255 255 255 / 8%);
  width: 100%;
  margin-bottom: 1rem;
  color: #fcfcfc;
  vertical-align: top;
  border-color: rgb(255 255 255 / 12%);
}

html.dark-theme .table-light {
  --bs-table-bg: rgb(255 255 255 / 12%);
  --bs-table-striped-bg: #ecedee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfe0e1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5e6e7;
  --bs-table-hover-color: #000;
  color: #fff;
  border-color: #dfe0e1;
}

/* Emailbox */

html.dark-theme .email-wrapper {
  background-color: #202a40;
  box-shadow: 0 0.1rem 0.7rem rgb(0 0 0 / 24%);
}
html.dark-theme .email-sidebar {
  background-color: #202a40;
  border-right: 1px solid rgb(255 255 255 / 0.12);
}
html.dark-theme .email-sidebar-header {
  background-color: #202a40;
  border-bottom: 1px solid rgb(255 255 255 / 0.12);
  border-right: 0 solid rgb(255 255 255 / 12%);
}
html.dark-theme .email-navigation {
  border-bottom: 1px solid rgb(255 255 255 / 13%);
}
html.dark-theme .email-navigation a.list-group-item {
  color: #e4e5e6;
  background-color: #202a40;
  border-bottom: 1px solid rgb(0 0 0 / 0%);
}
html.dark-theme .email-navigation a.list-group-item:hover {
  background-color: rgb(255 255 255 / 14%);
}
html.dark-theme .email-meeting a.list-group-item {
  color: #e4e5e6;
  background-color: #202a40;
  border-bottom: 1px solid rgb(0 0 0 / 0%);
}
html.dark-theme .email-meeting a.list-group-item:hover {
  background-color: rgb(255 255 255 / 14%);
}
html.dark-theme .list-group-item {
  background-color: #202a40;
  border: 1px solid rgb(255 255 255 / 13%);
}
html.dark-theme .email-navigation a.list-group-item.active {
  color: #ffffff;
  font-weight: 600;
  background-color: rgb(255 255 255 / 14%);
}
html.dark-theme .list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
html.dark-theme .border-top {
  border-top: 1px solid rgb(255 255 255 / 13%) !important;
}
html.dark-theme .email-header {
  background-color: #202a40;
  border-bottom: 1px solid rgb(255 255 255 / 13%);
}
html.dark-theme .email-content {
  background: #202a40;
}
html.dark-theme .email-list div.email-message {
  background-color: #202a40;
  border-bottom: 1px solid rgb(255 255 255 / 12%);
  color: #e4e5e6;
}
html.dark-theme .email-list div.email-message:hover {
  transition: all 0.2s ease-out;
  background: #434a50;
}

html.dark-theme .email-time {
  font-size: 13px;
  color: #8c969e;
}

/* Chatbox */

html.dark-theme .chat-wrapper {
  background-color: #202a40;
  box-shadow: 0 0.1rem 0.7rem rgb(0 0 0 / 22%);
}
html.dark-theme .chat-sidebar {
  background-color: #202a40;
  border-right: 1px solid rgb(255 255 255 / 13%);
}
html.dark-theme .chat-sidebar-header {
  background-color: #202a40;
  border-bottom: 1px solid rgb(255 255 255 / 13%);
  border-right: 0 solid rgb(255 255 255 / 13%);
}
html.dark-theme .chat-header {
  background-color: #202a40;
  border-bottom: 1px solid rgb(255 255 255 / 13%);
}
html.dark-theme .chat-tab-menu li a.nav-link {
  color: #c3c5c8;
}
html.dark-theme .chat-title {
  color: #e4e5e6;
}
html.dark-theme .chat-msg {
  font-size: 14px;
  color: #848992;
}
html.dark-theme .chat-time {
  font-size: 13px;
  color: #8c969e;
}
html.dark-theme .chat-top-header-menu a {
  color: #c3c5c8;
  background-color: #202a40;
  border: 1px solid rgb(255 255 255 / 15%);
}
html.dark-theme .chat-content-leftside .chat-left-msg {
  background-color: #202a40;
}
html.dark-theme .chat-content-rightside .chat-right-msg {
  background-color: #41484e;
}
html.dark-theme .chat-footer {
  background-color: #202a40;
  border-top: 1px solid rgb(255 255 255 / 13%);
}
html.dark-theme .chat-footer-menu a {
  color: #c3c5c8;
  background-color: #202a40;
  border: 1px solid rgb(255 255 255 / 15%);
}

html.dark-theme .fm-menu .list-group a {
  color: #c3c5c8;
}
html.dark-theme .fm-icon-box {
  background: #202a40;
  color: #fff;
}
html.dark-theme .contacts-social a {
  background: #202a40;
  color: #fff;
}

html.dark-theme .fc-theme-standard td,
html.dark-theme .fc-theme-standard th {
  border: 1px solid rgb(255 255 255 / 0.12);
}
html.dark-theme .fc-theme-standard .fc-scrollgrid {
  border: 1px solid rgb(255 255 255 / 12%);
}
html.dark-theme .fc .fc-col-header-cell-cushion {
  color: #fff;
}
html.dark-theme .fc .fc-daygrid-day-number {
  color: #fff;
}

html.dark-theme .breadcrumb {
  background-color: rgb(32 42 64 / 0%);
}
html.dark-theme .breadcrumb-item.active {
  color: #8b969f;
}
html.dark-theme .breadcrumb-title {
  border-right: 1.5px solid rgb(255 255 255 / 18%);
}

html.dark-theme .bg-body {
  background-color: #202a40 !important;
}
html.dark-theme .swal2-popup {
  background-color: #161d2d !important;
  color: #ffffff !important;
}
html.dark-theme .swal2-title,
html.dark-theme .offcanvas,
html.dark-theme .card,
html.dark-theme .table > :not(caption) > * > * {
  color: #fff;
}

html.dark-theme .apexcharts-pie-label,
html.dark-theme .apexcharts-datalabels,
html.dark-theme .apexcharts-datalabel,
html.dark-theme .apexcharts-datalabel-label,
html.dark-theme .apexcharts-datalabel-value {
  fill: #fff;
}

html.dark-theme .apexcharts-text tspan {
  fill: rgb(255 255 255 / 66%);
}
.histroy-long ul.pagination {
  display: block;
}
.histroy-long ul.pagination li.page-item {
  display: inline-block;
}

/* Responsive */

@media screen and (max-width: 1024px) {
  html.dark-theme .top-header .navbar .full-searchbar {
    background-color: #1a2232;
    border-bottom: 1px solid rgb(255 255 255 / 15%);
    background-clip: border-box;
  }
}

.target_text {
  margin: 0px auto;
  margin-top: 40%;
  background: #f8f8f8;
  width: 80px;
  border: 1px solid #ddd;
  padding: 2px;
  border-radius: 2px;
  color: #111;
}

#target {
  border-radius: 150px;
}
.target {
  border: 1px solid;
  margin: 15px 10%;
  width: 165px;
  height: 165px;
  display: inline-block;
}

/* 404 page css  */
#notfound {
  font-family: Roboto, sans-serif !important;
  position: fixed;
  min-height: 100vh;
  width: 100%;
  background: white;
  z-index: 111111111111111;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

#notfound .notfound {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
#notfound .notfound {
  max-width: 920px;
  width: 100%;
  line-height: 1.4;
  text-align: center;
  padding-left: 15px;
  padding-right: 15px;
}
#notfound .notfound .notfound-404 {
  position: absolute;
  height: 100px;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: -1;
}
#notfound .notfound .notfound-404 h1 {
  color: #ececec;
  font-weight: 900;
  font-size: 276px;
  margin: 0px;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-family: Roboto, sans-serif !important;
}
#notfound .notfound h2 {
  font-family: Roboto, sans-serif !important;
  font-size: 46px;
  color: #000;
  font-weight: 900;
  text-transform: uppercase;
  margin: 0px;
}
#notfound .notfound p {
  font-size: 16px;
  color: #000;
  font-weight: 400;
  text-transform: uppercase;
  margin-top: 15px;
}
#notfound .notfound button {
  font-size: 14px;
  text-decoration: none;
  text-transform: uppercase;
  background: var(--secondary-color);
  display: inline-block;
  padding: 16px 38px;
  border: 2px solid transparent;
  border-radius: 40px;
  color: #fff;
  font-weight: 400;
  -webkit-transition: 0.2s all;
  transition: 0.2s all;
}
#notfound .notfound button:hover {
  background-color: #fff;
  border-color: var(--secondary-color);
  color: var(--secondary-color);
}

@media only screen and (max-width: 480px) {
  #notfound .notfound .notfound-404 h1 {
    font-size: 162px;
  }
  #notfound .notfound h2 {
    font-size: 26px;
  }
  #notfound .notfound p {
    font-size: 14px;
  }
  #notfound .notfound button {
    font-size: 12px;
    padding: 5px 20px;
  }
}

.gradx_hide {
  display: none;
}

.gradx {
  position: relative;
  background: #fafafa;
  border: 4px solid #dfdfdf;
  padding: 10px;
  font-weight: normal;
  width: 444px;
  font-size: 12px;
}

.gradx textarea {
  font-size: 12px;
}

.gradx_container {
  padding: 10px;
  background: white;
  padding-left: 16px;
  border: 5px solid #e5e5e5;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -o-border-radius: 2px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.gradx_panel {
  width: 400px;
  height: 42px;
  border: 1px solid #ccc;
}

.gradx_start_sliders {
  width: 412px;
  margin-top: 8px;
  margin-left: -0.4em;
  background: whitesmoke;
  height: 12px;
  box-shadow: 5px 9px 30px rgba(0, 0, 0, 0.5) inset;
  -webkit-box-shadow: 5px 9px 30px rgba(0, 0, 0, 0.5) inset;
  -moz-box-shadow: 5px 9px 30px rgba(0, 0, 0, 0.5) inset;
  -o-box-shadow: 5px 9px 30px rgba(0, 0, 0, 0.5) inset;

  border: 1px solid #f1f1f1;
}

.gradx_slider {
  position: absolute;
  left: 0px;

  width: 10px;
  height: 10px;
  background: red;
  border: 1px solid black;
  display: inline-block;
  opacity: 0.6;
}

.gradx_slider:hover {
  opacity: 1;
  cursor: move;
}

.gradx_slectboxes select {
  color: black;
  vertical-align: baseline;
}

.gradx_slider:after {
  content: "";
  position: absolute;
  bottom: 10px;
  left: 4px;
  border-style: solid;
  border-width: 70px 1px 0px 0px;
  border-color: transparent #000;
  display: block;
  width: 0;
  z-index: 1;
}

.gradx_code,
gradx_code:focus {
  padding: 8px;
  color: #111;
  text-shadow: #eee 0 1px;
  background: #bba;
  margin-top: 5px;
  border-radius: 4px;
  font-size: 12px;
  display: none;
  overflow: auto;
  width: 100%;
  min-height: 200px;
  border: 1px solid #ccc;
  resize: vertical;
  font-family: "Maven Pro", sans-serif;
  border: 0;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  -o-box-shadow: none !important;
  line-height: 14px;

  /* the long awaited dream of 100% width comes true */
  /* All hail CSS3 */
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
}

#gradx_slider_info {
  display: none;
  border: 1px solid #ccc;
  padding: 2px;
  background: #f1f1f1;
}

#gradx_slider_controls {
  margin-left: 15px;
  padding-top: 4px;
  padding-bottom: 4px;
}

.gradx_add_slider {
  border: 1px solid black;
  width: 50px;
  margin-bottom: 4px;
}

.gradx_slectboxes {
  display: inline;
}

.gradx_gradient_type {
  height: 25px;
  background: #efefef;
  border: 1px solid #ccc;
  padding-right: 2px;
  width: 115px;
  margin: 0;
}

.gradx_btn > .icon {
  position: relative;
  left: -3px;
  background-size: 12px 12px;
  top: 2px;
  display: inline-block;
  height: 12px;
  width: 12px;
  background-position: 0 0;
}

/* .gradx_btn > .icon-add {
  background-image: url("icons/plus.png");
  left: -10px;
}

.gradx_btn > .icon-remove {
  background-image: url("icons/remove.png");
}

.gradx_btn > .icon-file-css {
  background-image: url("icons/file-css.png");
}

.gradx_btn > .icon-preset {
  background-image: url("icons/preset.png");
} */

/* Common stuff */
.picker-wrapper,
.slide-wrapper {
  position: relative;
  float: left;
}
.picker-indicator,
.slide-indicator {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
}
.picker,
.slide {
  cursor: crosshair;
  float: left;
}

/* Default skin */

.cp-default {
  border-radius: 6px;
  float: left;
  margin-top: 13px;
  margin-left: -96px;
  position: absolute;
  z-index: 10000;
}

.cp-default .picker {
  width: 200px;
  height: 200px;
}
.cp-default .slide {
  width: 30px;
  height: 200px;
}
.cp-default .slide-wrapper {
  margin-left: 10px;
}
.cp-default .picker-indicator {
  width: 5px;
  height: 5px;
  border: 2px solid darkblue;
  -moz-border-radius: 4px;
  -o-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  opacity: 0.5;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=50);
  filter: alpha(opacity=50);
  background-color: white;
}
.cp-default .slide-indicator {
  width: 100%;
  height: 10px;
  left: -4px;
  opacity: 0.6;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=60)";
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=60);
  filter: alpha(opacity=60);
  border: 4px solid black;
  -moz-border-radius: 4px;
  -o-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  background-color: #333;
}

/* some bootstrap styles */

.gradx_btn {
  display: inline-block;
  padding: 4px 10px 4px;
  font-size: 13px;
  line-height: 18px;
  color: #333333;
  text-align: center;
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);
  vertical-align: middle;
  cursor: pointer;
  background-color: #f5f5f5;

  background-repeat: repeat-x;
  border: 1px solid #cccccc;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  border-color: #e6e6e6 #e6e6e6 #bfbfbf;
  border-bottom-color: #b3b3b3;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;

  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2),
    0 1px 2px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2),
    0 1px 2px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2),
    0 1px 2px rgba(0, 0, 0, 0.05);
}

.gradx_btn:hover,
.gradx_btn:active,
.gradx_btn.active,
.gradx_btn.disabled,
.gradx_btn[disabled] {
  background-color: #e6e6e6;
}

.gradx_btn:active,
.gradx_btn.active {
  background-color: #cccccc \9;
}

.gradx_btn:hover {
  color: #333333;
  text-decoration: none;
  background-color: #e6e6e6;
  /* Buttons in IE7 don't get borders, so darken on hover */

  background-position: 0 -15px;
  -webkit-transition: background-position 0.1s linear;
  -moz-transition: background-position 0.1s linear;
  -ms-transition: background-position 0.1s linear;
  -o-transition: background-position 0.1s linear;
  transition: background-position 0.1s linear;
}

.gradx_btn:focus {
  outline: thin dotted #333;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

.gradx_btn.active,
.gradx_btn:active {
  background-color: #e6e6e6;
  background-color: #d9d9d9 \9;
  background-image: none;
  outline: 0;
  -webkit-box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15),
    0 1px 2px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15),
    0 1px 2px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05);
}

.gradx_btn.disabled,
.gradx_btn[disabled] {
  cursor: default;
  background-color: #e6e6e6;
  background-image: none;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
/* card CSS */
.pirds {
  margin: 0;
  padding: 0;
  width: 130px;
  height: 73px;
  padding: 0px;
  color: #fff;
  text-decoration: none;
  display: inline-flex;
  flex-wrap: wrap;
  text-align: center;
  -webkit-border-radius: 23px;
  -moz-border-radius: 23px;
  border-radius: 23px;
  margin-right: 3px;
  background: #2b85c6;
  background: -webkit-gradient(
    linear,
    0 0,
    0 bottom,
    from(#2b85c6),
    to(#1f69af)
  );
  background: -webkit-linear-gradient(#2b85c6, #1f69af);
  background: -moz-linear-gradient(#2b85c6, #1f69af);
  background: -ms-linear-gradient(#2b85c6, #1f69af);
  background: -o-linear-gradient(#2b85c6, #1f69af);
  background: linear-gradient(#2b85c6, #1f69af);
}

.form-check.form-switch .pirds {
  display: flex;
  margin: 0 auto;
}

.rdcrdtop {
  width: 100%;
}

.rdcrdtop table {
  border: none;
  width: 100%;
  text-align: center;
}

.mws-table tbody td:first-child,
.mws-table tfoot td:first-child {
  border-left: none;
}

.rdcrdtop table tr td {
  margin: 0;
  padding: 0;
  text-decoration: none !important;
  color: #fff;
  font-family: Arial, Helvetica, sans-serif !important;
  font-size: 12px;
  display: block;
  line-height: 18px;
  width: 100%;
  height: 50px;
  text-align: center;
  text-transform: uppercase;
  font-weight: normal;
  background: none !important;
  display: table-cell;
  border-right-width: 0px !important;
}

.rdcrd {
  margin: 0;
  padding: 0;
  text-decoration: none !important;
  color: #f3eb31;
  font-family: Arial, Helvetica, sans-serif !important;
  font-size: 12px;
  width: 100%;
  display: block;
  text-align: center;
  font-stretch: condensed;
  text-transform: uppercase;
}
